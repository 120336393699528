<template>
  <table width="800" border="0" cellspacing="30" cellpadding="0">
		
					<tbody><tr>
						<td><div align="center"><font size="+2">Extended Depth of Field: Egypt</font></div></td>
					</tr>
				<tr>
					<td>This example demonstrates that our camera can capture scenes with a large depth of field while using a large aperture to ensure high SNR. <b>Click on the thumbnails to play videos.</b></td>
				</tr>
				<tr><td><table border="0" width="800" align="center"><tbody><tr><td><table border="0" width="100%" align="center"><tbody><tr><td class="gallery"><center><a href="https://www.youtube.com/watch?v=SZtRBkkW_Ec"><img src="Captured_s.png" width="390"></a><br><b>Captured Video</b><br>(<em>f</em>/1.4)</center></td><td class="gallery"><center><a href="https://www.youtube.com/watch?v=RUzm2Ipq7Jw"><img src="EDOF_s.png" width="390"></a><br><b>Computed EDOF Video</b></center></td></tr>
<tr><td class="gallery"><center><a href="https://www.youtube.com/watch?v=erq8kS8-RHw"><img src="NormalF1.4_s.png" width="390"></a><br><b>Video from Normal Camera</b><br>(<em>f</em>/1.4, Fixed Focus)</center></td><td class="gallery"><center><a href="https://www.youtube.com/watch?v=RVyLYaAxHEE"><img src="NormalF8_s.png" width="390"></a><br><b>Video from Normal Camera</b><br>(<em>f</em>/8, Fixed Focus) with Scaling</center></td></tr>
</tbody></table></td></tr></tbody></table></td></tr><tr><td><hr></td></tr>
			<tr><td height="20"><a href="/CAVE/projects/flexible_dof/">Back to project page</a></td></tr>
			</tbody></table>
</template>

<script>
export default {

}
</script>

<style>

</style>